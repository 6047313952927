import axios from 'axios';
class ApiService  {       

    getData(apiurl,apidata) {

        return axios.post(apiurl, apidata, {withCredentials: true})
        .then(response => {
            return response.data;
        });
    }
}

const exportData = new ApiService();
export default exportData;
