import QRCode from "react-qr-code";

const RecipientView = ({code}) => {

    if (code === 'notransmitter'){
        return (
        <div>
            <h1>Vorgang nicht möglich</h1>
            <p>Es ist kein Kurier unterwegs.</p>
        </div>
        )
    }

    else {
        return (
        <div>
            <h1>Empfangscode</h1>
            <p>Bitte den Code vom Kurier scannen lassen.</p>
            <QRCode className="qrcode" value={window.location.origin + window.location.pathname + '?rcode=' + code} fgColor={'#cdcdcd'} bgColor={"transparent"} /> 
        </div>
        )
    }

}

export default RecipientView;
