const TransmitterView = ({ response }) => {

    if (response === 'success') {
        return (
            <div>
                <h1>Scan erfolgreich</h1>
                <p>Wir wünschen eine gute Fahrt.</p>
            </div>
        );
    }
    if (response === 'stationsuccess') {
        return (
            <div>
                <h1>Scan erfolgreich</h1>
                <p>Sie können die Tour jetzt fortsetzen.</p>
            </div>
        );
    }
    if (response === 'existing') {
        return (
            <div>
                <h1>Scan fehlgeschlagen</h1>
                <p>Dieser Code wurde bereits gescannt.</p>
            </div>
        );
    }

    if (response === 'finished') {
        return (
            <div>
                <h1>Scan erfolgreich</h1>
                <p>Vielen Dank für die Abgabe.</p>
            </div>
        );
    }

    if (response === 'wrong transmitter') {
        return (
            <div>
                <h1>Scan fehlgeschlagen</h1>
                <p>Dieser Code passt nicht zum Kurier.</p>
            </div>
        )
    }


    if (response === 'wrong code') {
        return (
            <div>
                <h1>Scan fehlgeschlagen</h1>
                <p>Dieser Code existiert nicht.</p>
                <p>Bitten lassen Sie von Ihrem Gegenüber einen neuen Code erzeugen.</p>
            </div>
        )
    }

}

export default TransmitterView;
