import { Component } from 'react';


export default class ErrorBoundary extends Component {
    
    state = {
        hasError: false,
        errormessage: ''
    };
    
    
    static getDerivedStateFromError(error) {
        return { 
            hasError: true,
            errormessage: error
        };
    }
    
    componentDidCatch(error) {
        if (!this.state.hasError) {
            if (error.name === 'AxiosError'){
                this.setState({errormessage: error.code});
            }
            else {
                this.setState({errormessage: error});
            }
        }
    }
    
    render() {
        if (this.state.hasError) {                  
            let contentsection;
            switch(this.state.errormessage) {                           
                case 'ERR_NETWORK':
                    contentsection = <p><strong>Die Verbindung zu unserem Server wurde leider unterbrochen.</strong><br />In wenigen Minuten sollte alles wieder funktionieren.</p>
                    break;                 
                case 'SERVER_NO_ANSWER':  
                    contentsection = <p>Der Server hat leider keine Antwort geliefert.</p>
                    break;
                case 'NO_CONNECTION':  
                    contentsection = <p>Derzeit ist leider keine Verbindung zu unserem Server möglich.<br />In wenigen Minuten sollte alles wieder funktionieren.</p>
                    break;
                default:
                    contentsection = <p>In wenigen Minuten sollte alles wieder funktionieren.</p>
                    break;          
            }

            return (
            <div className="container t-center">
                <h1>Ein Fehler ist aufgetreten</h1>
                {contentsection}                
            </div>
            );
        }
        return this.props.children;
    }

}
