import './css/main.css';
import ErrorBoundary from './components/ErrorBoundary';
import MainView from './components/MainView';

const App = () => {
    return (
        <div className="wrapper">
            <ErrorBoundary>
                <MainView />
            </ErrorBoundary>
        </div>
    );
}

export default App;
