import QRCode from "react-qr-code";

const StationView = ({ code }) => {
    if (code === 'finished') {
        return (
            <div>
                <h1>Vorgang nicht möglich</h1>
                <p>Der Kurier war bereits an dieser Station.</p>
            </div>
        )
    }

    else {
        return (
            <div>
                <h1>Stationscode</h1>
                <p>Bitte den Code vom Kurier scannen lassen.</p>
                {<QRCode className="qrcode" value={window.location.origin + window.location.pathname + '?stcode=' + code} fgColor={'#cdcdcd'} bgColor={"transparent"} />}
            </div>
        )
    }

}

export default StationView;
