import { useState } from 'react';
import ApiService from "../../services/api.service";

const LoginView = ({ setActiveUser }) => {

    const [error, setError] = useState(null);
    const [code, setCode] = useState();
    const [inputsCount, setInputsCount] = useState(0);

    function checkCode(pin) {
        const API_URL = global.config.express.url + '/checkpincode';
        const API_DATA = {
            'pincode': pin
        }
        ApiService.getData(API_URL, API_DATA).then((user) => {
            if (user) {
                setActiveUser(user);
            }
            else {
                let lines = document.getElementsByClassName('inputs')[0].children;
                setCode();
                setError('error');
                setTimeout(function () {
                    setError(null);
                    setInputsCount(0);
                    for (var i = 0; i < lines.length; i++) {
                        lines[i].classList.remove('active');
                    }
                }, 2000);
            }
        });
    };

    function setInput(e) {
        e.target.classList.add('clicked');
        setTimeout(function () {
            e.target.classList.remove('clicked');
        }, 500);
        document.getElementsByClassName('inputs')[0].children[inputsCount].classList.add('active');
        typeof (inputsCount) === 'undefined' ? setInputsCount(1) : setInputsCount(inputsCount + 1);
        let pin;
        if (typeof (code) === 'undefined') {
            pin = e.target.innerText;
        }
        else {
            pin = code + e.target.innerText;
        }
        setCode(pin);
        if (pin.length === 5) {
            checkCode(pin);
        }
    }

    let buttons = [];
    for (let i = 0; i < 10; i++) {
        buttons.push(
            <div className="input" key={'k' + i}>
                <button type="button" onClick={(e) => setInput(e)}>{i}</button>
            </div>
        )
    }
    buttons.push(buttons.shift());

    return (
        <div className="pincode">
            <h1>Pincode</h1>
            {error === 'error' ? <p className="error">Falscher Code</p> : null}
            <div className="inputs">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="inputfield">
                {buttons}
            </div>
        </div>
    );
}

export default LoginView;
