import { useEffect, useState } from 'react';
import ApiService from "../services/api.service";
import LoginView from './views/LoginView';
import SenderView from './views/SenderView';
import TransmitterView from './views/TransmitterView';
import RecipientView from './views/RecipientView';
import StationView from './views/StationView';

const MainView = () => {

    const queryParameters = new URLSearchParams(window.location.search);
    const sendercode = queryParameters.get("scode");
    const stationcode = queryParameters.get("stcode");
    const recipientcode = queryParameters.get("rcode");

    const [activeUser, setActiveUser] = useState(null);
    const [view, setView] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {

        if (!activeUser) {
            setView(<LoginView setActiveUser={setActiveUser} />);
        }
        else {
            // Sender
            if (activeUser.role === 'sender') {
                if (!sendercode && !recipientcode && !stationcode) {
                    const API_URL = global.config.express.url + '/sendercode';
                    const API_DATA = {
                        'sender': activeUser.id
                    }
                    ApiService.getData(API_URL, API_DATA).then((response) => {
                        setView(
                            <SenderView code={response} />
                        );
                    }).catch((error) => { setError(error) });
                }
                else {
                    setView(
                        <div>
                            <h1>Fehler</h1>
                            <p>Als Sender ist man nicht berechtigt den Code zu scannen.</p>
                        </div>
                    );
                }
            }

            // Station
            if (activeUser.role === 'station') {
                if (!sendercode && !recipientcode && !stationcode) {
                    const API_URL = global.config.express.url + '/stationcode';
                    const API_DATA = {
                        'station': activeUser.id
                    }
                    ApiService.getData(API_URL, API_DATA).then((response) => {
                        setView(
                            <StationView code={response} />
                        );
                    }).catch((error) => { setError(error) });
                }
                else {
                    setView(
                        <div>
                            <h1>Fehler</h1>
                            <p>Als Mitarbeiter einer Abholstation ist man nicht berechtigt den Code zu scannen.</p>
                        </div>
                    );
                }
            }


            // Transmitter
            if (activeUser.role === 'transmitter') {
                if (sendercode) {
                    const API_URL = global.config.express.url + '/transmitterstart';
                    const API_DATA = {
                        'transmitter': activeUser.id,
                        'sendercode': sendercode
                    }
                    ApiService.getData(API_URL, API_DATA).then((response) => {
                        setView(
                            <TransmitterView response={response} />
                        );
                    }).catch((error) => { setError(error) });
                }

                else if (stationcode) {
                    const API_URL = global.config.express.url + '/addstation';
                    const API_DATA = {
                        'transmitter': activeUser.id,
                        'stationcode': stationcode
                    }
                    ApiService.getData(API_URL, API_DATA).then((response) => {
                        setView(
                            <TransmitterView response={response} />
                        );
                    }).catch((error) => { setError(error) });
                }

                else if (recipientcode) {
                    const API_URL = global.config.express.url + '/transmitterstop';
                    const API_DATA = {
                        'transmitter': activeUser.id,
                        'recipientcode': recipientcode
                    }
                    ApiService.getData(API_URL, API_DATA).then((response) => {
                        setView(
                            <TransmitterView response={response} />
                        )
                    }).catch((error) => { setError(error) });
                }
                else {
                    setView(
                        <div>
                            <h1>Scan erforderlich</h1>
                            <p>Bitte scanne einen gültigen QR-Code.</p>
                        </div>
                    );
                }
            }

            // Recipient
            if (activeUser.role === 'recipient') {
                if (!sendercode && !recipientcode && !stationcode) {
                    const API_URL = global.config.express.url + '/recipientcode';
                    const API_DATA = {
                        'recipient': activeUser.id
                    }
                    ApiService.getData(API_URL, API_DATA).then((response) => {
                        setView(
                            <RecipientView code={response} />
                        );
                    }).catch((error) => { setError(error) });
                }
                else {
                    setView(
                        <div>
                            <h1>Fehler</h1>
                            <p>Als Empfänger ist man nicht berechtigt den Code zu scannen.</p>
                        </div>
                    );
                }
            }

        }

    }, [activeUser, sendercode, stationcode, recipientcode]);

    if (error) throw error;

    return (
        <div className="container t-center">
            {view}
        </div>
    );
}

export default MainView;
