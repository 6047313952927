import QRCode from "react-qr-code";

const SenderView = ({code}) => {

    if (code === 'existing'){
        return (
        <div>
            <h1>Vorgang nicht möglich</h1>
            <p>Es ist bereits ein Kurier unterwegs.</p>
        </div>
        )
    }

    else {
        return (
        <div>
            <h1>Absendecode</h1>
            <p>Bitte den Code vom Kurier scannen lassen.</p>
            <QRCode className="qrcode" value={window.location.origin + window.location.pathname + '?scode=' + code} fgColor={'#cdcdcd'} bgColor={"transparent"} /> 
        </div>
        )
    }
    
}

export default SenderView;
